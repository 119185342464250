/* ================================= Range Slider Css Start =========================== */
.custom--range {
	#slider-range {
		height: 4px;
		border: 0;
		background: var(--gray-200);
		margin-top: 5px;
		.ui-slider-handle {
			width: 14px !important;
			height: 14px !important;
			background-color: var(--main-600) !important;
			border-radius: 50%;
			top: 50%;
			transform: translateY(-50%);
			border: 0 !important;
			outline: 0 !important;
			transition: .2s linear;
			&:hover, &.ui-state-active {
				transform: translateY(-50%) scale(1.3);
				box-shadow: 0px 0px 12px 4px #9595956b !important;  
			}
			&::before {
				position: absolute;
				content: "";
				width: 5px;
				height: 5px;
				border-radius: 50%;
				background-color: hsl(var(--white));
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
		.ui-widget-header {
			background-color: var(--main-600);
		}
		span {
			&:focus {
				background-color: var(--main-600);
			}
		}
	}
	input {
		border: 0;
		color: hsl(var(--body-color));
		font-weight: 500;
		outline: 0;
	}
    &__content {
        input {
            width: 100%;
            text-align: right;
        }
    }
}
/* ================================= Range Slider Css End =========================== */