/* ================================= Tab Css Start =========================== */
.common-tab {
    margin-block-end: 0;
    .nav-item {
        border-bottom: 0;
        .nav-link {
            color: var(--gray-600);
            padding: 4px 12px;
            border: 1px solid var(--gray-200);
            border-radius: 50px;
            font-size: rem(15px);
            &.active {
                color: var(--main-600);
                background-color: var(--main-50);
                border-color: var(--main-50);
            }
            &:hover {
                color: var(--main-600);
                border-color: var(--main-200);
            }
        }
    }

    &.style-two {
        gap: clampCal(4, 24, 992, 1599);
        .nav-item {
            .nav-link {
                padding: 9px 16px;
                border-radius: 12px 12px 0 0;
                border: 0;
                @include sm-screen {
                    padding: 6px 12px;
                    font-size: rem(13px) !important;
                }
            }
        }
    }
}
/* ================================= Tab Css End =========================== */
