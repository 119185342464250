/* ============================= Mentor Card Css Start ============================= */
.mentor-card {
    &__cover {
        min-height: 98px;
        img {
            min-height: inherit;
        }
    }
    &__content {
        border-radius: inherit;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border: 1px solid var(--gray-100);
        border-top: 0;
        margin-top: -28px;
        position: relative;
        z-index: 1;
        padding: 16px;
        padding-block-start: 0 !important;
        @include xl-screen {
            padding: 8px;
        }
    }
    &__img {

    }
    &__rating {
        gap: 4px;
    }
}
.follow-btn {
    &:hover {
        background-color: hsl(var(--white)/.25) !important;
    }
    &.active {
        background-color: var(--main-600) !important;
        border-color: var(--main-600) !important;
    }
}
/* ============================= Mentor Card Css End ============================= */