/* ============================ Image Upload Css Start ==================== */
.image-upload {
    border-radius: 8px !important;
    overflow: hidden;
    position: relative;
    border-radius: inherit;
    border: 1px solid var(--main-200) !important;
    background-color: var(--main-50);
    cursor: pointer;
    transition: 0.2s;
    &:hover {
        background-color: var(--main-100);
    }    
    &__box {
        border-radius: inherit !important;
        cursor: pointer;
        padding: 16px !important;
        flex-direction: column;
        justify-content: center;
        height: 206px;
    }
    &__boxInner {
        padding: 0 !important;
        border: 0 !important;
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        width: auto !important;
        margin: 0 auto;
        position: relative;
        border-radius: inherit;
    }
    &__icon {
        color: inherit !important;
        font-size: 72px !important;
    }
    &__image {
        border-radius: inherit;
        max-width: 190px;
    }
    &__imageName {
        margin-top: 4px;
        color: hsl(var(--body-color));
    }
    &__deleteBtn {
        position: absolute;
        right: -6px;
        top: -6px;
        z-index: 1;
        background: var(--gray-100);
        color: hsl(var(--gray-900));
        border-radius: 50%;
        width: 20px;
        height: 20px;
        font-size: 12px;
        transition: 0.2s;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            background: var(--danger-600);
            transform: scale(1.1);
            color: #fff;
        }
    }
    &__anotherFileIcon {
        font-size: 16px !important;
        color: hsl(var(--body-color)) !important;
        width: 40px;
        height: 40px;
        border: 1px solid var(--gray-100);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: inherit;
    }
}


.image-upload__boxInner p, .image-upload__boxInner span {
    line-height: 1.2 !important;
}
/* ============================ Image Upload Css End ==================== */