/* ================================ Step List Css Start ========================== */
.step-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    gap: 10px;
    @include xl-screen {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    @include sm-screen {
        grid-template-columns: repeat(auto-fit, minmax(154px, 1fr));
    }
    &__item {
        background-color: hsl(var(--white));
        clip-path: polygon(0 0, 94% 0, 100% 50%, 94% 100%, 0 100%, 6% 47%);
        @media (min-width: 1500px) {
            clip-path: polygon(0 0, 96% 0, 100% 50%, 96% 100%, 0 100%, 4% 47%);
        }
        @include xxl-screen {
            font-size: rem(12px) !important;
            padding: 10px 16px !important;
        }
        &:last-child {
            .line {
                display: none;
            }
        }
        .icon {
            color: var(--gray-200);
        }
        .line {
            width: 47px;
            @include xl-screen {
                width: 40px;
            }
            @include sm-screen {
                display: none;
            }
            &::before, &::after {
                position: absolute;
                content: "";
                width: 100%;
                height: 1px;
                inset-inline-start: 0;
                inset-inline-end: 50%;
                transform: translateY(-50%);
                background-color: var(--gray-200);
            }
            &::after {
                content: "\E13A";
                font-family: 'Phosphor';
                inset-inline-start: auto;
                inset-inline-end: -5px;
                width: unset;
                height: unset;
                background-color: transparent;
                height: unset;
                color: var(--gray-200);
            }
        }
        
        /* Done Style */
        &.done {
            background-color: var(--main-600);
            color: #fff !important;
            .icon {
                color: #fff;
                i::before {
                    content: "\E184";
                }
            }
            .line {
                &::before {
                    background-color: #fff;
                }
                &::after {
                    color: #fff;
                }
            }
        }
        
        /* active Style */
        &.active {
            color: var(--main-600) !important;
            .icon {
                color: var(--main-600);
            }
            .line {
                &::before {
                    background-color: var(--main-600);
                }
                &::after {
                    color: var(--main-600);
                }
            }
        }
    }
}
/* ================================ Step List Css End ========================== */