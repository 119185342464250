/* ================================== Upload Phooto Css Start ======================= */
.avatar-upload {
    position: relative;
    input {
        display: none;
    }
    .avatar-preview {
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 100%;
        > div {
            width: 100%;
            height: 100%;
            border-radius: inherit;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
} 


.cover-img {
    .avatar-upload .avatar-preview {
        width: 100%;
        height: 168px; 
        border-radius: 0px;

    }
}
/* ================================== Upload Phooto Css End ======================= */