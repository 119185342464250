/* =============================== Chat Css Start =========================== */
.chat-list {
    &__item {
        &:not(:last-child) {
            margin-bottom: 24px;
        }
    
    }
}

.chat-list {
    width: 264px;
    @include sm-screen {
        width: 100%;
    }
    &-wrapper {
        max-height: 710px;
    }
}
.chat-box-item-wrapper {
    max-height: 740px;
    &.style-email {
        max-height: calc(100vh - 374px);
    }
}

.chat-box {
    width: calc(100% - 288px);
    @include sm-screen {
        width: 100%;
    }
    &-item {
        &.right {
            justify-content: end;
            flex-direction: row-reverse;
            text-align:  end;
            .chat-box-item {
                &__content {
                    align-items: end;
                }
                &__text {
                    border-radius: 16px 16px 0 16px;
                    background-color: #E5F3FF;
                }
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
            align-items: start;
        }
        &__text {
            background-color: #ECF1F9;
            border-radius: 16px 16px 16px 0;
            max-width: 500px;
            display: inline-block;
        }
    }
}   

.chat-box-bottom {
    @include sm-screen {
        gap: 4 !important;
        .file-btn {
            height: 38px !important;
            width: 38px !important;
            font-size: 18px !important;
        }
        .submit-btn {
            height: 38px !important;
            padding: 8px 16px;
            font-size: 12px;
        }
        input {
            height: 38px !important;
        }
    }
}

/* =============================== Chat Css End =========================== */