/* ================================= Pagination Css Start =========================== */
.pagination {
    .page-item {
        .page-link {
            border: 1px solid transparent;
            background-color: transparent;
            color: var(--gray-300);
            font-size: rem(15px);
            box-shadow: none !important;
            padding: 0;
        }
        &.active {
            .page-link {
                border-color: var(--main-100);
                background-color: var(--main-50);
                color: var(--main-600);
            }
        }
    }
}
/* ================================= Pagination Css End =========================== */