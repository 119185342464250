
/* Vector Map Css Start */
#world-map {
    height: 115px !important;
    // background-color: var(--gray-100);
}
.map-sm {
    height: 160px !important;
}
.jvectormap-goback, .jvectormap-zoomin, .jvectormap-zoomout {
    background: #D1D5DB;
    color: #111827;
}
/* Vector Map Css End */