/* ================================= Margin Css Start =========================== */
@each $property, $value in $spaces {
  .m-#{$property} {
    margin: var(--size-#{$property}) !important;
  }
}

@each $property, $value in $spaces {
  .mx-#{$property} {
    margin-inline: var(--size-#{$property}) !important;
  }
}

@each $property, $value in $spaces {
  .my-#{$property} {
    margin-block: var(--size-#{$property}) !important;
  }
}

@each $property, $value in $spaces {
  .ms-#{$property} {
    margin-inline-start: var(--size-#{$property}) !important;
  }
}

@each $property, $value in $spaces {
  .me-#{$property} {
    margin-inline-end: var(--size-#{$property}) !important;
  }
}

@each $property, $value in $spaces {
  .mt-#{$property} {
    margin-block-start: var(--size-#{$property}) !important;
  }
}

@each $property, $value in $spaces {
  .mb-#{$property} {
    margin-block-end: var(--size-#{$property}) !important;
  }
}

.translate-n74 {
  transform: translateY(-74px); 
}

.mt-n74 {
  margin-block-start: -74px;
}

.mt-74 {
  margin-block-start: 74px;
}

.mt-0 {
  margin-block-start: 0!important;
}

.mb-0 {
  margin-block-end: 0!important;
}

.ms-0 {
  margin-inline-start: 0!important;
}

.me-0 {
  margin-inline-end: 0!important;
}

.ms-n12 {
  margin-inline-start: -12px;
}

/* ================================= Margin Css End =========================== */
