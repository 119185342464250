
// // Colors
@each $color, $shades in $colors {
  @each $shade, $value in $shades {
    .text-#{$color}-#{$shade} {
      color: var(--#{$color}-#{$shade}) !important;
    }
  }
}


.text {
  &-heading {
      color: hsl(var(--heading-color)) !important;
  }
  &-body {
      color: hsl(var(--body-color)) !important;
  }
  &-main {
      color: hsl(var(--main)) !important;
  }
}

@each $color, $shades in $colors {
  @each $shade, $value in $shades {
    .hover-text-#{$color}-#{$shade} {
      &:hover {
          color: var(--#{$color}-#{$shade}) !important;
      }
    }
  }
}

/* Text Color */
.hover-text {
 &-white {
      &:hover {
          color: #fff !important;
      }
  }
  &-heading {
      &:hover {
          color: hsl(var(--heading-color)) !important;
      }
  }
  &-body {
      &:hover {
          color: hsl(var(--body-color)) !important;
      }
  }
  &-main {
      &:hover {
          color: hsl(var(--main)) !important;
      }
  }
}

.text-white {
  color: white !important;
}
