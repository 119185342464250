
/* ================================= Classes Css Start =========================== */
/* Section Background */
.section-bg {
    background-color: hsl(var(--section-bg)) !important;
}

/* Column Extra Small Screen */
.col-xs-6 {
    @media screen and (min-width: 425px) and (max-width: 575px) {
        width: 50%;
    }
}

/* Transition */
.transition {
    &-1 {
        transition: .1s linear;
    }
    &-2 {
        transition: .2s linear;
    }
    &-3 {
        transition: .3s linear;
    }
}

.min-width-max-content {
    min-width: max-content;
}

.inset {
    &-inline-start-0 {
        inset-inline-start: 0;
    }
    &-inline-end-0 {
        inset-inline-end: 0;
    }
    &-block-start-0 {
        inset-block-start: 0;
    }
    &-block-start-100 {
        inset-block-start: 100%;
    }
    &-block-end-0 {
        inset-block-end: 0;
    }
}

.start {
    &-auto {
        inset-inline-start: auto !important;
    }
}

.end {
    &-auto {
        inset-inline-end: auto !important;
    }
}

/* Text Color */
.hover-text {
    &-decoration-underline {
        &:hover {
            text-decoration: underline;
        }
    }
    &-decoration-none {
        &:hover {
            text-decoration: none;
        }
    }
}

.font {
    &-heading {
        font-family: var(--heading-font);
    }
    &-body {
        font-family: var(--body-font);
    }
}


/* Bg Image Css */
.bg-img {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    width: 100%;
    height: 100%;
}

.pointer-event-none {
    pointer-events: none;
}
.cursor-pointer {
    cursor: pointer;
}

.list-inside {
    list-style: inside;
}

.min-w-max-content {
    min-width: max-content;
}
/* ================================= Classes Css End =========================== */