/* ======================= Common Table Css Start ======================= */
.table-list {
    > span {
        position: relative;
        margin-inline-end: 8px;
        padding-inline-end: 8px;
        &:last-child {
            margin-inline-end: 0;
            padding-inline-end: 0;
            &::before {
                display: none;
            }
        }
        &::before {
            position: absolute;
            content: "";
            width: 1px;
            height: 7px;
            inset-inline-end: 0;
            inset-block-start: 50%;
            transform: translateY(-50%);
            background-color: var(--main-600);
        }
    }
}

.table>:not(caption)>*>* {
    border-bottom-width: 0;
}
.table {
    min-width: max-content;
    &.style-two {
        th {
            background-color: var(--gray-50);
            border-bottom: 0;
        }
        th, td {
            padding: 16px 20px;
        }
        td {
            width: 33.33%;
            word-wrap: break-word; 
        }
        tbody tr {
            border-bottom: 1px solid var(--gray-100);
            &:last-child {
                border-bottom: 0 !important;
            }
        }
    }

    &.style-three {
        tbody {
            tr {
                border-bottom: 1px solid var(--gray-50);
                td {
                    padding: 24px 20px !important;
                }
            }
        }
    }
}
/* ======================= Common Table Css End ======================= */